import axios from 'axios';
import { Mutex } from 'async-mutex';
import { TokensStoreProvider } from '../stores/tokens.store';
import { AuthClient } from '~/app-modules/auth/clients/auth.client';
import { type TokensPairDto } from '~/app-modules/auth/clients/dto/tokens-pair.dto';
import type { UserDto } from '~/app-modules/users/clients/dto/user.dto';

@singleton()
export class AuthService {
  constructor(protected tokensStore: TokensStoreProvider, protected authClient: AuthClient) {}

  private _mutex = new Mutex();

  get Bearer(): String {
    return this.tokensStore.getStore().accessToken;
  }

  get isLoggedIn(): boolean {
    return this.tokensStore.getStore().tokens !== null;
  }

  async authorizeBySsoCode(authorizationCode: string) {
    try {
      if (this.isLoggedIn) {
        this.logout();
      }
      const tokensPair = await this.authClient.authorizeBySsoCode(authorizationCode);
      this.tokensStore.getStore().setTokens(tokensPair);
      //
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 400) {
          useToast().error('Авторизация через SSO недоступна');
          throw error;
        } else {
          console.log(error);
          throw error;
        }
      } else {
        throw error;
      }
    }
  }

  async getSsoSignInUrl(
    backUrl: string,
    currentUser?: UserDto | undefined,
    registerFirst?: boolean
  ) {
    try {
      const { url } = await this.authClient.getSsoSignInUrl();

      const confirmCookie = useCookie('fishplan-market-cookie-confirm');

      if (this.isLoggedIn && currentUser) {
        const { userId, organizationId } = currentUser;
        return `${url}&backUrl=${backUrl}&userId=${userId}&organizationId=${organizationId || ''}${
          confirmCookie ? '&fishplan-cookie-confirm=true' : ''
        }`;
      }

      return `${url}${registerFirst ? '&registerFirst' : ''}&backUrl=${backUrl}${
        confirmCookie ? '&fishplan-cookie-confirm=true' : ''
      }`;
      //
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 400) {
          useToast().error('Авторизация через SSO недоступна');
          throw error;
        } else {
          console.log(error);
          throw error;
        }
      } else {
        throw error;
      }
    }
  }

  async getSsoSignUpUrl() {
    try {
      // TODO: разработка api метода для getSsoSignUpUrl
      const { url } = await this.authClient.getSsoSignInUrl();
      return url.split('/auth/')[0] + '/registration/user/';
      //
    } catch (error) {
      if (axios.isAxiosError(error)) {
        useToast().error('Регистрация недоступна');
        throw error;
      } else {
        throw error;
      }
    }
  }

  updateTokens(tokensPair: TokensPairDto) {
    this.tokensStore.getStore().setTokens(tokensPair);
  }

  async ensureTokensRefreshed() {
    if (this._mutex.isLocked()) return await this._mutex.waitForUnlock();

    const router = useRouter();
    try {
      if (!this.tokensStore.getStore().refreshToken) return;

      await this._mutex.runExclusive(async () => {
        const tokensPair = await this.authClient.refresh(this.tokensStore.getStore().refreshToken);
        this.tokensStore.getStore().setTokens(tokensPair);
      });
    } catch (error) {
      console.error(error);
      this.logout();
      await router.push('/auth/');
    }
  }

  logout() {
    this.tokensStore.getStore().dropTokens();
  }
}
