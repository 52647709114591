import { default as indexMiqCHENiq9Meta } from "/builds/coingroup/fishcoin/market-client/pages/auth/index.vue?macro=true";
import { default as blankPZUjUpPPJvMeta } from "/builds/coingroup/fishcoin/market-client/pages/blank.vue?macro=true";
import { default as indexa30ykuyHxAMeta } from "/builds/coingroup/fishcoin/market-client/pages/cart/checkout/index.vue?macro=true";
import { default as indexXRZJrqsSMFMeta } from "/builds/coingroup/fishcoin/market-client/pages/cart/index.vue?macro=true";
import { default as indexHeetkvrCwwMeta } from "/builds/coingroup/fishcoin/market-client/pages/catalog/[[organizationId]]/index.vue?macro=true";
import { default as index3sMmIVEAxmMeta } from "/builds/coingroup/fishcoin/market-client/pages/catalog/product-[id]/index.vue?macro=true";
import { default as indext6HRQVboaDMeta } from "/builds/coingroup/fishcoin/market-client/pages/catalog/product-[id]/pellet-[pelletSize]mm/index.vue?macro=true";
import { default as package_45_91packageSize_93kgoH1GRHAKNnMeta } from "/builds/coingroup/fishcoin/market-client/pages/catalog/product-[id]/pellet-[pelletSize]mm/package-[packageSize]kg.vue?macro=true";
import { default as product_45_91id_93D0ByR48W4PMeta } from "/builds/coingroup/fishcoin/market-client/pages/catalog/product-[id].vue?macro=true";
import { default as _91id_935i8DMzFyZuMeta } from "/builds/coingroup/fishcoin/market-client/pages/feed-calculation/[id].vue?macro=true";
import { default as index0ypiqgv0ViMeta } from "/builds/coingroup/fishcoin/market-client/pages/feed-calculation/index.vue?macro=true";
import { default as updateL81WWWOiugMeta } from "/builds/coingroup/fishcoin/market-client/pages/fish-feed/[id]/update.vue?macro=true";
import { default as createRHzf9ghwg7Meta } from "/builds/coingroup/fishcoin/market-client/pages/fish-feed/create.vue?macro=true";
import { default as indexVJJRhO7u04Meta } from "/builds/coingroup/fishcoin/market-client/pages/fish-feed/index.vue?macro=true";
import { default as indexUdORLNg0BVMeta } from "/builds/coingroup/fishcoin/market-client/pages/lk/index.vue?macro=true";
import { default as sentry_45errorNaiBGPFos0Meta } from "/builds/coingroup/fishcoin/market-client/pages/sentry-error.vue?macro=true";
import { default as sentry_45ssr_45errorlhcG9ajWyrMeta } from "/builds/coingroup/fishcoin/market-client/pages/sentry-ssr-error.vue?macro=true";
import { default as story_45book_45vuetifyN8qTjQYtuZMeta } from "/builds/coingroup/fishcoin/market-client/pages/story-book-vuetify.vue?macro=true";
export default [
  {
    name: indexMiqCHENiq9Meta?.name ?? "auth",
    path: indexMiqCHENiq9Meta?.path ?? "/auth",
    meta: indexMiqCHENiq9Meta || {},
    alias: indexMiqCHENiq9Meta?.alias || [],
    redirect: indexMiqCHENiq9Meta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/market-client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: blankPZUjUpPPJvMeta?.name ?? "blank",
    path: blankPZUjUpPPJvMeta?.path ?? "/blank",
    meta: blankPZUjUpPPJvMeta || {},
    alias: blankPZUjUpPPJvMeta?.alias || [],
    redirect: blankPZUjUpPPJvMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/market-client/pages/blank.vue").then(m => m.default || m)
  },
  {
    name: indexa30ykuyHxAMeta?.name ?? "cart-checkout",
    path: indexa30ykuyHxAMeta?.path ?? "/cart/checkout",
    meta: indexa30ykuyHxAMeta || {},
    alias: indexa30ykuyHxAMeta?.alias || [],
    redirect: indexa30ykuyHxAMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/market-client/pages/cart/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexXRZJrqsSMFMeta?.name ?? "cart",
    path: indexXRZJrqsSMFMeta?.path ?? "/cart",
    meta: indexXRZJrqsSMFMeta || {},
    alias: indexXRZJrqsSMFMeta?.alias || [],
    redirect: indexXRZJrqsSMFMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/market-client/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexHeetkvrCwwMeta?.name ?? "catalog-organizationId",
    path: indexHeetkvrCwwMeta?.path ?? "/catalog/:organizationId?",
    meta: indexHeetkvrCwwMeta || {},
    alias: indexHeetkvrCwwMeta?.alias || [],
    redirect: indexHeetkvrCwwMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/market-client/pages/catalog/[[organizationId]]/index.vue").then(m => m.default || m)
  },
  {
    path: product_45_91id_93D0ByR48W4PMeta?.path ?? "/catalog/product-:id()",
    children: [
  {
    name: index3sMmIVEAxmMeta?.name ?? "catalog-product-id",
    path: index3sMmIVEAxmMeta?.path ?? "",
    meta: index3sMmIVEAxmMeta || {},
    alias: index3sMmIVEAxmMeta?.alias || [],
    redirect: index3sMmIVEAxmMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/market-client/pages/catalog/product-[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indext6HRQVboaDMeta?.name ?? "catalog-product-id-pellet-pelletSizemm",
    path: indext6HRQVboaDMeta?.path ?? "pellet-:pelletSize()mm",
    meta: indext6HRQVboaDMeta || {},
    alias: indext6HRQVboaDMeta?.alias || [],
    redirect: indext6HRQVboaDMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/market-client/pages/catalog/product-[id]/pellet-[pelletSize]mm/index.vue").then(m => m.default || m)
  },
  {
    name: package_45_91packageSize_93kgoH1GRHAKNnMeta?.name ?? "catalog-product-id-pellet-pelletSizemm-package-packageSizekg",
    path: package_45_91packageSize_93kgoH1GRHAKNnMeta?.path ?? "pellet-:pelletSize()mm/package-:packageSize()kg",
    meta: package_45_91packageSize_93kgoH1GRHAKNnMeta || {},
    alias: package_45_91packageSize_93kgoH1GRHAKNnMeta?.alias || [],
    redirect: package_45_91packageSize_93kgoH1GRHAKNnMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/market-client/pages/catalog/product-[id]/pellet-[pelletSize]mm/package-[packageSize]kg.vue").then(m => m.default || m)
  }
],
    name: product_45_91id_93D0ByR48W4PMeta?.name ?? undefined,
    meta: product_45_91id_93D0ByR48W4PMeta || {},
    alias: product_45_91id_93D0ByR48W4PMeta?.alias || [],
    redirect: product_45_91id_93D0ByR48W4PMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/market-client/pages/catalog/product-[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_935i8DMzFyZuMeta?.name ?? "feed-calculation-id",
    path: _91id_935i8DMzFyZuMeta?.path ?? "/feed-calculation/:id()",
    meta: _91id_935i8DMzFyZuMeta || {},
    alias: _91id_935i8DMzFyZuMeta?.alias || [],
    redirect: _91id_935i8DMzFyZuMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/market-client/pages/feed-calculation/[id].vue").then(m => m.default || m)
  },
  {
    name: index0ypiqgv0ViMeta?.name ?? "feed-calculation",
    path: index0ypiqgv0ViMeta?.path ?? "/feed-calculation",
    meta: index0ypiqgv0ViMeta || {},
    alias: index0ypiqgv0ViMeta?.alias || [],
    redirect: index0ypiqgv0ViMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/market-client/pages/feed-calculation/index.vue").then(m => m.default || m)
  },
  {
    name: updateL81WWWOiugMeta?.name ?? "fish-feed-id-update",
    path: updateL81WWWOiugMeta?.path ?? "/fish-feed/:id()/update",
    meta: updateL81WWWOiugMeta || {},
    alias: updateL81WWWOiugMeta?.alias || [],
    redirect: updateL81WWWOiugMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/market-client/pages/fish-feed/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: createRHzf9ghwg7Meta?.name ?? "fish-feed-create",
    path: createRHzf9ghwg7Meta?.path ?? "/fish-feed/create",
    meta: createRHzf9ghwg7Meta || {},
    alias: createRHzf9ghwg7Meta?.alias || [],
    redirect: createRHzf9ghwg7Meta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/market-client/pages/fish-feed/create.vue").then(m => m.default || m)
  },
  {
    name: indexVJJRhO7u04Meta?.name ?? "fish-feed",
    path: indexVJJRhO7u04Meta?.path ?? "/fish-feed",
    meta: indexVJJRhO7u04Meta || {},
    alias: indexVJJRhO7u04Meta?.alias || [],
    redirect: indexVJJRhO7u04Meta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/market-client/pages/fish-feed/index.vue").then(m => m.default || m)
  },
  {
    name: indexUdORLNg0BVMeta?.name ?? "lk",
    path: indexUdORLNg0BVMeta?.path ?? "/lk",
    meta: indexUdORLNg0BVMeta || {},
    alias: indexUdORLNg0BVMeta?.alias || [],
    redirect: indexUdORLNg0BVMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/market-client/pages/lk/index.vue").then(m => m.default || m)
  },
  {
    name: sentry_45errorNaiBGPFos0Meta?.name ?? "sentry-error",
    path: sentry_45errorNaiBGPFos0Meta?.path ?? "/sentry-error",
    meta: sentry_45errorNaiBGPFos0Meta || {},
    alias: sentry_45errorNaiBGPFos0Meta?.alias || [],
    redirect: sentry_45errorNaiBGPFos0Meta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/market-client/pages/sentry-error.vue").then(m => m.default || m)
  },
  {
    name: sentry_45ssr_45errorlhcG9ajWyrMeta?.name ?? "sentry-ssr-error",
    path: sentry_45ssr_45errorlhcG9ajWyrMeta?.path ?? "/sentry-ssr-error",
    meta: sentry_45ssr_45errorlhcG9ajWyrMeta || {},
    alias: sentry_45ssr_45errorlhcG9ajWyrMeta?.alias || [],
    redirect: sentry_45ssr_45errorlhcG9ajWyrMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/market-client/pages/sentry-ssr-error.vue").then(m => m.default || m)
  },
  {
    name: story_45book_45vuetifyN8qTjQYtuZMeta?.name ?? "story-book-vuetify",
    path: story_45book_45vuetifyN8qTjQYtuZMeta?.path ?? "/story-book-vuetify",
    meta: story_45book_45vuetifyN8qTjQYtuZMeta || {},
    alias: story_45book_45vuetifyN8qTjQYtuZMeta?.alias || [],
    redirect: story_45book_45vuetifyN8qTjQYtuZMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/market-client/pages/story-book-vuetify.vue").then(m => m.default || m)
  }
]